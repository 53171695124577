<template>
  <div>
    <update-modal
      :sku="selectSku"
      :show-modal="showModal"
      @close="showModal = false"
    />
    <b-card title="Vous pouvez rechercher les logistics directement ici.">
      <b-row>
        <b-col
          cols="12 mb-4"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher un sku..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="getLogistics"
              />
            </b-input-group>
            <flat-pickr
              v-model="dateFilter"
              class="form-control flat-picker bg-white border-0 shadow-none ml-4"
              placeholder="YYYY-MM-DD"
              :config="{ disable:[{from:'2020-08-20',to:'2022-10-23'}]}"
              @input="getLogistics"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          v-if="logistics && logistics.length"
          cols="2"
        >
          <download-csv
            :data="logistics.map(l => ({sku: l.sku, type: l.alert ? 'A COMMANDER' : 'STOCK SUFFISANT', ordersWaiting: l.waitingOrders && l.waitingOrders.length ? l.waitingOrders.join(' / ') : '', agediss: l.agediss, colisland: l.colisland, stock: l.stock, total: l.total, logisticien: l.logisticien, component: l.composeSku }))"
            name="logistic.csv"
          >
            <b-button
              class="mb-2"
              variant="warning"
            >
              Télécharger
            </b-button>
          </download-csv>
        </b-col>
        <b-col
          v-if="logistics && logistics.length"
          cols="2"
        >
          <v-select
            v-model="pagination"
            :options="[20, 50, 100, 200, 'All']"
            transition=""
            @input="getLogistics"
          />
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row
        v-else
        class="match-height"
      >
        <b-table
          ref="selectableTable"
          selectable
          :items="logistics"
          :fields="fields"
          :busy="loading"
          @row-clicked="openModal"
        >
          <template #cell(sku)="data">
            <p>{{ data.item.sku }}</p>
            <div
              v-if="data.item.compose"
              class="text-warning"
            >
              <p
                v-for="(compose, keyC) of data.item.compose"
                :key="keyC"
                class="my-0"
              >
                {{ compose.sku }} <strong @click="setFilter(compose.sku)">({{ compose.total }})</strong>
              </p>
            </div>
            <span v-if="data.item.dispatchClass"> <br>{{ data.item.dispatchClass }}</span>
          </template>
          <template #cell(stock)="data">
            <b-badge :variant="`light-${data.item.stock ? 'success' : 'danger'}`">
              {{ data.item.stock ? 'EN STOCK' : 'OUT OF STOCK' }}
            </b-badge>
          </template>
          <template #cell(agediss)="data">
            <b-badge :variant="`light-${data.item.agediss < 5 ? 'danger' : 'success'}`">
              {{ data.item.agediss }}
            </b-badge>
          </template>
          <template #cell(colisland)="data">
            <b-badge :variant="`light-${data.item.colisland < 5 ? 'danger' : 'success'}`">
              {{ data.item.colisland }}
            </b-badge>
          </template>
          <template #cell(waiting)="data">
            <b-badge
              v-b-tooltip.hover
              :title="data.item.waitingOrders.join(' / ')"
              :variant="`light-${data.item.waiting > 0 ? 'danger' : 'success'}`"
            >
              {{ data.item.waiting }}
            </b-badge>
          </template>
          <template #cell(onProcessing)="data">
            <b-badge
              v-b-tooltip.hover
              :title="data.item.onProcessingOrders.join(' / ')"
              :variant="`light-${data.item.onProcessing > 0 ? 'danger' : 'success'}`"
            >
              {{ data.item.onProcessing }}
            </b-badge>
          </template>
          <template #cell(onHold)="data">
            <b-badge
              v-b-tooltip.hover
              :title="data.item.onHoldOrders.join(' / ')"
              :variant="`light-${data.item.onHold > 0 ? 'danger' : 'success'}`"
            >
              {{ data.item.onHold }}
            </b-badge>
          </template>
          <template #cell(total)="data">
            <b-badge :variant="`light-${data.item.total < 5 ? 'danger' : 'success'}`">
              {{ data.item.total }}
            </b-badge>
          </template>
          <template #cell(quantiteRemaining)="data">
            <b-badge v-if="data.item.quantiteRemaining" variant="warning" class="text-center">
              {{ data.item.quantiteRemaining.remaining }} unités <br> le {{ $moment(data.item.quantiteRemaining.dateShipping).format('DD/MM/YYYY') }}
            </b-badge>
          </template>
          <!-- <template #cell(status)="data">
              {{ data }}
            </template> -->
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BInputGroup,
  BBadge,
  BTable,
  VBPopover,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import JsonCSV from 'vue-json-csv'
import UpdateModal from '@/components/logistic/UpdateModal.vue'

export default {
  name: 'LogisticList',
  components: {
    BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    BBadge,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    'download-csv': JsonCSV,
    BButton,
    flatPickr,
    vSelect,
    UpdateModal,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    skuBase: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dateFilter: null,
      pagination: 20,
      selectSku: null,
      required,
      baseLogistics: [],
      logistics: [],
      products: [],
      categories: [],
      logistic: {},
      conditionalProducts: '',
      conditionalCategories: '',
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'sku', sortable: true }, 'stock', { key: 'agediss', sortable: true }, { key: 'colisland', sortable: true }, { key: 'onProcessing', label: 'Proces.', sortable: true }, { key: 'waiting', label: 'Waiting', sortable: true }, { key: 'onHold', label: 'On Hold', sortable: true }, { key: 'total', label: 'Total', sortable: true }, { key: 'quantiteRemaining', label: 'Réappro.', sortable: true }, { key: 'logCategory', label: 'Category', sortable: true }],
      showModal: false,
      categoryInformations: [],
      loading: false,
      timer: null,
    }
  },
  async mounted() {
    try {
      if (this.skuBase) {
        console.log('skuBase', this.skuBase)
        this.code = this.skuBase
      }
      await this.getLogistics()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    openModal(element) {
      this.selectSku = element
      this.showModal = true
    },
    getQuantityStock(sku) {
      const logSku = this.baseLogistics.find(l => l.sku.toLowerCase() === sku.toLowerCase())
      if (logSku) {
        return logSku.agediss - logSku.waiting - logSku.onHold
      }
      return 'Introuvable'
    },
    async getLogistics() {
      try {
        clearTimeout(this.timer)
        this.timer = setTimeout(async () => {
          this.loading = true
          let params = { date: this.dateFilter, pagination: this.pagination }
          if (this.code !== '') {
            params = { ...params, sku: this.code }
          }
          const {
            logistics,
          } = await this.$http.get('/admin/logistic', { params: { ...params } })
          this.logistics = logistics
          this.baseLogistics = logistics
          this.loading = false
        }, 500)
      } catch (err) {
        console.log(err)
      }
    },
    setFilter(value) {
      this.code = value
      this.getLogistics()
    },
  },
}
</script>

  <style scoped>
   .jsoneditor-poweredBy {
     display: none;
   }
   [dir] .table th, [dir] .table td {
    padding: 0.72rem !important;
   }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
